@keyframes heartbeat {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes spin {
    0% { 
        transform: rotate(0deg); 
    }
    100% { 
        transform: rotate(360deg); 
    }
}

@keyframes fadeIn {
    0% { 
        opacity: 0; 
    }
    100% { 
        opacity: 1; 
    }
}

.containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100vh;
    background-color: #f8f8f8;
}

.logos {
    width: 200px;
    height: 150px;
    margin-bottom: 16px;
    animation: heartbeat 1s ease-in-out infinite;
}

.loaders {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite, fadeIn 1s ease-in-out;
    margin-bottom: 16px;
}

.texts {
    font-size: 24px;
    color: #333;
}
